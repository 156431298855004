import { z } from 'zod';

export const organizationTeamSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type OrganizationTeam = z.infer<typeof organizationTeamSchema>;

export const currentUserSchema = z.object({
  id: z.number(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  // TODO: should be hardoded list of roles potentially to help reduce bugs around checking roles
  role: z.string(),
  internal_communication: z.boolean().default(false),
  sales: z.boolean().default(false),
  aggregate_send_only: z.boolean().default(false),
  social_reaction_enabled: z.boolean().default(false),
  sr_comment_enabled: z.boolean().default(false),
  events_management_enabled: z.boolean().default(false),
  list_management_enabled: z.boolean(),
  sms_campaigns_enabled: z.boolean(),
  timezone: z.string().nullable(),
  mail_merge_campaigns_enabled: z.boolean(),
  send_mail_merge_campaigns_enabled: z.boolean(),
  is_sso: z.boolean(),
  gmail_dashboard_sending_enabled: z.boolean(),
  aggregate_and_anonymous_send_only: z.boolean(),
  click_maps_enabled: z.boolean(),
  show_campaign_template: z.boolean().default(false),
  readtrack_enabled: z.boolean(),
  // is dateTime but does zod do it
  joined_ic_org_at: z.coerce.date().nullable(),
  sms_campaign_trial_enabled: z.boolean(),
  sms_trial_campaigns_sent: z.number(),
  outlook_dashboard_sending_enabled: z.boolean(),
  // where we paused
  has_created_template: z.boolean(),
  outlook_dashboard_sent_successfully: z.boolean(),
  bee_ai_meta_tag_enabled: z.boolean(),
  // maybe enum
  region: z.string(),
  can_sign_in_to_outlook_mail: z.boolean(),
  signed_in_to_outlook: z.boolean(),
  can_sign_in_to_gsuite: z.boolean(),
  gsuite_connected: z.boolean(),
  organization: z.object({
    id: z.number(),
    name: z.string(),
    template_enabled: z.boolean(),
    email_translations_enabled: z.boolean(),
    email_translations_toggle_visible: z.boolean(),
    events_management_enabled: z.boolean(),
    scheduled_sends_enabled: z.boolean(),
    default_expand_distribution_lists_in_ms_graph: z.boolean(),
    plan_type: z.string(),
    // is only date
    expires_on: z.coerce.date(),
    org_type: z.string(),
    ms_teams_enabled: z.boolean(),
    ms_sharepoint_enabled: z.boolean(),
    ms_sharepoint_requested: z.boolean(),
    social_channels_enabled: z.boolean(),
    custom_fonts_enabled: z.boolean(),
    custom_landing_page_enabled: z.boolean(),
    remaining_licenses: z.number(),
    is_azure_ready: z.object({
      graph_enabled: z.boolean(),
      utility_account_connected: z.boolean(),
    }),
    active_contacts_count: z.number(),
    okta_enabled: z.boolean(),
    dynamic_content_enabled: z.boolean(),
  }),
  organization_languages: z.array(z.object({
    id: z.number(),
    code: z.string(),
  })),
  feature_flags: z.object({
    ff_surveys_v2: z.boolean().optional().default(false),
    ff_survey_v2_stats_show: z.boolean().optional().default(false),
    ff_survey_v2_export_button_show: z.boolean().optional().default(false),
    ff_ms_sharepoint: z.boolean().optional().default(false),
    ff_dynamic_content_v1: z.boolean().optional().default(false),
    ff_async_campaign_sending: z.boolean(),
  }),
  // could be hardcoded list of languages, leaving it for now as it may be an issue if hardcoded
  // TODO: check with tripod if i can hardcode this
  languages_map: z.object({
    'en-US': z.string(),
    'es-ES': z.string(),
    'fr-FR': z.string(),
    'it-IT': z.string(),
    'pt-BR': z.string(),
    'id-ID': z.string(),
    'ja-JP': z.string(),
    'zh-CN': z.string(),
    'zh-HK': z.string(),
    'de-DE': z.string(),
    'da-DK': z.string(),
    'sv-SE': z.string(),
    'pl-PL': z.string(),
    'hu-HU': z.string(),
    'ru-RU': z.string(),
    'ko-KR': z.string(),
    'nl-NL': z.string(),
    'fi-FI': z.string(),
    'cs-CZ': z.string(),
    'ro-RO': z.string(),
    'nb-NO': z.string(),
    'sl-SI': z.string(),
    'el-GR': z.string(),
    'uk-UA': z.string(),
    'mn-MN': z.string(),
    'sr-SP': z.string(),
    'mg-MG': z.string(),
    'zu-ZA': z.string(),
  }),
  organization_team: organizationTeamSchema,
  organization_teams: z.array(organizationTeamSchema),
});
export type User = z.infer<typeof currentUserSchema>;

// only doing this because the backend wraps the whole user response in a user object
// please do not do this anywhere else moving forward
export const UserApiSchema = z.object({
  user: currentUserSchema,
});

export type UserApi = z.infer<typeof UserApiSchema>;
